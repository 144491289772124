import React from "react";
import Tag from "../UI/Tag";
import { StyledInnerCard } from "../styles/InnerCard";
import { useEffect, useState, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import ReactGA from "react-ga4";
import Bandeira from "../UI/Bandeira";
import {
  convertMoney,
  wageConference,
  convertDate,
  compararDatasClasse,
  compararDatasNome,
} from "../tools/functions";
import InnercardInfo from "../components/InnercardInfo";
import Carregando from "../components/Carregando";

ReactGA.initialize("G-9EJ6PS0PBE");

function InnerCard() {
  const [data, setData] = useState(null);
  const { id } = useParams();

  const Token = process.env.REACT_APP_TOKEN;
  const URL = process.env.REACT_APP_API_URL;

  // TAMANHOS DA SEÇÃO "LOCAIS DE TRABALHO" se algum desses mudar de altura é necessário mudar aqui para a animação continuar rodando corretamente
  const tittleHeight = 22.39;
  const tittleGapHeight = 16;

  const fixedHeight = tittleHeight + tittleGapHeight;

  const itensCountOpenedRef = useRef(0);
  const itensCountClosed = itensCountOpenedRef.current > 1 ? 2 : 1;
  const itemGap = 24;
  const itemHeight = 52.77;
  const animationTime = 1;

  let flexTittleStatesHeightClosed;
  let flexTittleStatesHeightOpened;

  if (itensCountClosed > 1) {
    flexTittleStatesHeightClosed =
      fixedHeight + itensCountClosed * itemGap + itensCountClosed * itemHeight;
  } else {
    flexTittleStatesHeightClosed = fixedHeight + itemHeight;
  }

  if (itensCountOpenedRef.current > 1) {
    flexTittleStatesHeightOpened =
      fixedHeight +
      itensCountOpenedRef.current * itemGap +
      itensCountOpenedRef.current * itemHeight;
  } else {
    flexTittleStatesHeightOpened = fixedHeight + itemHeight;
  }

  const [loading, setLoading] = useState(true);

  const [buttonOpened, setButtonOpened] = useState(false);
  const [animationPlayStateStatus, setAnimationPlayStateStatus] =
    useState("paused");
  const [animationClassname, setAnimationClassname] = useState("");

  const estados = {
    PR: "Paraná",
    AL: "Alagoas",
    PA: "Pará",
    AP: "Amapá",
    SE: "Sergipe",
    RN: "Rio Grande do Norte",
    SC: "Santa Catarina",
    RO: "Rondônia",
    TO: "Tocantins",
    MA: "Maranhão",
    PI: "Piauí",
    RS: "Rio Grande do Sul",
    MG: "Minas Gerais",
    SP: "São Paulo",
    AC: "Acre",
    PB: "Paraíba",
    RJ: "Rio de Janeiro",
    MS: "Mato Grosso do Sul",
    RR: "Roraima",
    GO: "Goiás",
    CE: "Ceará",
    ES: "Espírito Santo",
    PE: "Pernambuco",
    BA: "Bahia",
    DF: "Distrito Federal",
    MT: "Mato Grosso",
    AM: "Amazonas",
    Diversos: "Nacional",
  };

  const [listStateCity, setListStateCity] = useState(null);
  const [officeObject, setOfficeObject] = useState(null);
  const [anotherNoticesDone, setAnotherNoticesDone] = useState(null);
  const [noticeOpeningDone, setNoticeOpeningDone] = useState(null);

  useEffect(() => {
    fetch(`${URL}/contest/contest/${id}/`, {
      headers: {
        Authorization: `Token ${Token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        setData(response);
        
        setLoading(false);

        document.title = `${response.short_name} ${response.contest_number} - Alô Concursos`;

        const groupedByState = {};

        let iteration;
        let officeList;
        let noticeOpening = [];
        let anotherNotices = [];

        officeList = response.offices;
        if (response.location_work_name === "Diversos") {
          if (response.location_work_override[0] === null) {
            iteration = ["Nacional, Diversos"];
          } else {
            iteration = response.location_work_override;
          }
        } else {
          iteration = [response.location_work_name];
        }

        response.pdfs.forEach((item) => {
          if (item.document_type === 1) {
            noticeOpening.push(item);
          } else if (item.document_type !== 1) {
            anotherNotices.push(item);
          }
        });

        iteration.forEach((location) => {
          const [city, state] = location.split(", ").map((item) => item.trim());

          if (!groupedByState[state]) {
            groupedByState[state] = [];
          }

          groupedByState[state].push(city);
        });

        setListStateCity(groupedByState);
        setOfficeObject(officeList);
        setNoticeOpeningDone(noticeOpening);
        if (anotherNotices.length !== 0) {
          setAnotherNoticesDone(anotherNotices);
        }
        itensCountOpenedRef.current = Object.keys(groupedByState).length;
      });
  }, [Token, URL, id]);

  if (loading) {
    return <Carregando />;
  }

  function animateControl() {
    setAnimationClassname("desexpand");
    setButtonOpened((prevButtonOpened) => !prevButtonOpened);
    setAnimationPlayStateStatus("running");

    setTimeout(() => {
      setAnimationPlayStateStatus("paused");
    }, animationTime * 1000);
  }
  return (
    <StyledInnerCard>
      <div className="flexHeader">
        <section className="tittle">
          <Tag
            nome={compararDatasNome(
              data.registration_opening,
              data.registration_deadline
            )}
            classname={compararDatasClasse(
              data.registration_opening,
              data.registration_deadline
            )}
          />
          <div className="flexTittle">
            <img
              className="mobileLogo"
              src={
                data.icon
                  ? "https://aloconcursos-staging-files20231022184301831600000001.s3.amazonaws.com/uploads/icons/4ba31314-e245-44b3-b92b-54ce4a8e2453.svg"
                  : "https://aloconcursos-staging-files20231022184301831600000001.s3.amazonaws.com/uploads/icons/4ba31314-e245-44b3-b92b-54ce4a8e2453.svg"
              }
              alt="logotipo do orgão"
            />
            <h1>{data ? data.name : null}</h1>
            <img
              className="desktopLogo"
              src={
                data.icon
                  ? "https://aloconcursos-staging-files20231022184301831600000001.s3.amazonaws.com/uploads/icons/4ba31314-e245-44b3-b92b-54ce4a8e2453.svg"
                  : "https://aloconcursos-staging-files20231022184301831600000001.s3.amazonaws.com/uploads/icons/4ba31314-e245-44b3-b92b-54ce4a8e2453.svg"
              }
              alt="logotipo do orgão"
            />
          </div>
        </section>

        <section className="flexFigures">
          <InnercardInfo
            className="gridItem"
            tittle={wageConference(data.wage_range)}
            subTittle={"Salário"}
          />
          <InnercardInfo
            className="gridItem"
            tittle={`${data.vacancies !== 0 ? data.vacancies : "CR"}`}
            subTittle={"Vagas"}
          />
          <InnercardInfo
            className="gridItem"
            tittle={
              data.organization.short_name ? data.organization.short_name : null
            }
            subTittle={"Banca"}
          />
          <InnercardInfo
            className="gridItem"
            tittle={`${convertDate(data.registration_opening)} a ${convertDate(
              data.registration_deadline
            )}`}
            subTittle={"Data da inscrição"}
          />
          <InnercardInfo
            css={"gridItem5"}
            tittle={`${
              data.registration_fee ? convertMoney(data.registration_fee) : null
            }`}
            subTittle={"Valor da inscrição"}
          />
        </section>
      </div>

      <section>
        <div
          style={{
            "--start-height": `${flexTittleStatesHeightClosed}px`,
            "--end-height": `${flexTittleStatesHeightOpened}px`,
            "--animation-time": `${animationTime}s`,
            animationPlayState: `${animationPlayStateStatus}`,
          }}
          className={`flexTittleStates ${
            buttonOpened ? "expand" : animationClassname
          }`}
        >
          {listStateCity && Object.keys(listStateCity).length > 1 ? (
            <h4 className="tittle">Locais de Trabalho</h4>
          ) : (
            <h4 className="tittle">Local de Trabalho</h4>
          )}
          <div className="flexListStates">
            {listStateCity && console.log(listStateCity)}
            {listStateCity &&
              Object.keys(listStateCity).map((estado, index) => {
                return (
                  <div className="flexStateCity" key={index}>
                    <div className="flexState">
                      <h3 className="stateName">{estados[estado]}</h3>
                      <Bandeira nomeDoEstado={estado} />
                    </div>

                    {listStateCity[estado][0] === "Nacional" ? null : (
                      <p className="city">
                        {listStateCity[estado].length > 1
                          ? listStateCity[estado].join(", ")
                          : listStateCity[estado][0]}
                      </p>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
        {listStateCity &&
          Object.keys(listStateCity).length > itensCountClosed && (
            <button className="showMoreButton" onClick={animateControl}>
              mostrar mais
            </button>
          )}
      </section>

      <section className="officeSection">
        {officeObject && officeObject.length > 1 ? (
          <h2>Cargos</h2>
        ) : (
          <h2>Cargo</h2>
        )}
        <p>
          {Object.values(officeObject).map((item, index) => {
            let lastItem = Object.values(officeObject).length - 1;
            if (index === lastItem) {
              return item;
            }
            return item + " | ";
          })}
        </p>
      </section>

      <section className="notices">
        {noticeOpeningDone ? (
          noticeOpeningDone.length > 1 ? (
            <h2>Editais de abertura</h2>
          ) : (
            <h2>Edital de abertura</h2>
          )
        ) : null}
        {noticeOpeningDone
          ? noticeOpeningDone.map((item, index) => {
              return (
                <div className="notice">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                  >
                    <mask
                      id="mask0_2863_19"
                      style={{ maskType: "alpha" }}
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="25"
                      height="24"
                    >
                      <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_2863_19)">
                      <path
                        d="M9.32691 12.4423H10.2115V10.4423H11.3269C11.5782 10.4423 11.7885 10.3577 11.9577 10.1884C12.1269 10.0192 12.2115 9.80896 12.2115 9.55768V8.44228C12.2115 8.19099 12.1269 7.98074 11.9577 7.81152C11.7885 7.64229 11.5782 7.55768 11.3269 7.55768H9.32691V12.4423ZM10.2115 9.55768V8.44228H11.3269V9.55768H10.2115ZM13.2308 12.4423H15.1923C15.4436 12.4423 15.6538 12.3577 15.8231 12.1884C15.9923 12.0192 16.0769 11.809 16.0769 11.5577V8.44228C16.0769 8.19099 15.9923 7.98074 15.8231 7.81152C15.6538 7.64229 15.4436 7.55768 15.1923 7.55768H13.2308V12.4423ZM14.1154 11.5577V8.44228H15.1923V11.5577H14.1154ZM17.1923 12.4423H18.0769V10.4423H19.2692V9.55768H18.0769V8.44228H19.2692V7.55768H17.1923V12.4423ZM8.55771 17.5C8.05258 17.5 7.62502 17.325 7.27504 16.975C6.92504 16.625 6.75004 16.1974 6.75004 15.6923V4.3077C6.75004 3.80257 6.92504 3.375 7.27504 3.025C7.62502 2.675 8.05258 2.5 8.55771 2.5H19.9423C20.4474 2.5 20.875 2.675 21.225 3.025C21.575 3.375 21.75 3.80257 21.75 4.3077V15.6923C21.75 16.1974 21.575 16.625 21.225 16.975C20.875 17.325 20.4474 17.5 19.9423 17.5H8.55771ZM8.55771 16H19.9423C20.0192 16 20.0897 15.9679 20.1538 15.9038C20.2179 15.8397 20.25 15.7692 20.25 15.6923V4.3077C20.25 4.23077 20.2179 4.16024 20.1538 4.09613C20.0897 4.03203 20.0192 3.99998 19.9423 3.99998H8.55771C8.48078 3.99998 8.41026 4.03203 8.34616 4.09613C8.28204 4.16024 8.24999 4.23077 8.24999 4.3077V15.6923C8.24999 15.7692 8.28204 15.8397 8.34616 15.9038C8.41026 15.9679 8.48078 16 8.55771 16ZM5.05774 20.9999C4.55262 20.9999 4.12506 20.8249 3.77506 20.4749C3.42506 20.1249 3.25006 19.6973 3.25006 19.1922V6.3077H4.75004V19.1922C4.75004 19.2692 4.78209 19.3397 4.84619 19.4038C4.9103 19.4679 4.98082 19.5 5.05774 19.5H17.9423V20.9999H5.05774Z"
                        fill="#1B2CC1"
                      />
                    </g>
                  </svg>
                  <a
                    key={index}
                    className="pdfLink"
                    target="_blank"
                    href={item.pdf_url}
                    rel="noopener noreferrer"
                  >
                    {item.name}
                  </a>
                </div>
              );
            })
          : null}

        {anotherNoticesDone && <h2>Atualizações</h2>}
      </section>
    </StyledInnerCard>
  );
}

export default InnerCard;
