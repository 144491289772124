import styled from "styled-components";

export const StyledSecondSearchBar = styled.form`
  display: flex;
  align-items: center;
  gap: 4rem;
  .logoSearch {
    width: 20rem;
    cursor: pointer;
  }
  .flexSearch {
    // width: 113rem;
    /* width: 100%; */
    /* min-width: 147.269rem; */
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1.6rem;
    gap: 1.6rem;
    height: 5.3rem;

    border-radius: 0.4rem;
    border: 1px solid var(--Grey-200);
    background-color: var(--Grey-100);
    .select {
      max-width: 13.4rem;
      max-height: 1.9rem;
    }
    .select__value-container {
      padding: 0;
    }
    .custom-single-value {
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: 0.8rem;
      width: 100%;
      img {
        width: 1.6rem;
        height: 1.6rem;
      }
      p {
        color: #33394d;
        font-family: "Work Sans";
        font-size: 1.6rem;
        font-weight: 500;
      }
    }
    .custom-dropdown-indicator {
      cursor: pointer;
    }
    .select__input-container {
      display: none;
    }
  }
  input {
    width: 100%;
    padding: 0.8rem 1.6rem;
    border-radius: 0.8rem;
  }

  .pipe {
    font-size: 2rem;
    color: #a0a5bd;
  }
  .flexInput {
    display: flex;
    width: 100%;

    input {
      background-color: transparent;
      width: 100%;
      border: none;
      padding: 1rem;
      outline: none;

      color: var(--Grey-900);
      font-family: "Work Sans";
      font-size: 1.6rem;
      font-weight: 400;
    }
  }
  .lupa {
    border: none;
    cursor: pointer;
  }

  .select__control {
    max-width: 14.3rem;
  }

  @media (max-width: 791px) {
    justify-content: center;
    min-width: 100% !important;
    .logoSearch {
      display: none;
    }
    .flexSearch {
      min-width: 100% !important;
    }
  }
`;
